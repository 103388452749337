import React, { useState, useRef } from 'react';
import { useVideoConfig, Audio, Img, Video, spring, interpolate, Sequence, staticFile, useCurrentFrame } from 'remotion';
import { BrainTeaserProps, AudioItem, Conversation, MediaItem } from './types';

const BrainTeaser = (props: BrainTeaserProps) => {
  //console.log('[BrainTeaserxxxx] Starting component render');
  const { fps, width, height } = useVideoConfig();
  const mediaItems = props.media_items || [];
  const backgroundVideoPath = props.backgroundVideoPath;
  const videoConfig = props.video_config || { fps, width, height };
  const chronoTime = props.chrono_time || 5;
  const defaultTimerSound = 'assets/sounds/timer-sound.mp3';
  const timerSound = props.timer_sound || defaultTimerSound;

  // Fonction utilitaire pour déterminer si c'est une URL
  const isURL = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  };

  //console.log('[BrainTeaserxxxx] Props received:', JSON.stringify({ 
  //  mediaItems, 
  //  backgroundVideoPath, 
  //  videoConfig, 
  //  chronoTime,
  //  timerSound
  //}, null, 2));

  // Précharger les ressources
  React.useEffect(() => {
    const preloadResources = async () => {
      try {
        if (backgroundVideoPath) {
          // Précharger la vidéo de fond
          const videoPreload = new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.src = backgroundVideoPath;
            video.onloadeddata = () => resolve(true);
            video.onerror = (e) => {
              console.error('[BrainTeaserxxxx errors] Video preload error:', {
                path: backgroundVideoPath,
                error: e
              });
              reject(e);
            };
          });

          // Précharger les images des personnages
          const imagePreloads = mediaItems.map(item => {
            if (item.type === 'conversation') {
              const conversation = item.data as Conversation;
              return [
                conversation.question?.characterPath,
                conversation.answer?.characterPath,
                conversation.commentary?.characterPath
              ].filter(Boolean);
            } else if (item.type === 'intro') {
              const audioItem = item.data as AudioItem;
              return [audioItem.characterPath];
            }
            return [];
          }).flat();

          const imagePromises = imagePreloads.map(path => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = path;
              img.onload = () => resolve(true);
              img.onerror = (e) => {
                console.error('[BrainTeaserxxxx errors] Image preload error:', {
                  path,
                  error: e
                });
                reject(e);
              };
            });
          });

          await Promise.all([videoPreload, ...imagePromises]);
          //console.log('[BrainTeaserxxxx] All resources preloaded successfully');
        }
      } catch (error) {
        console.error('[BrainTeaserxxxx errors] Resource preload failed:', {
          error,
          backgroundVideoPath,
          mediaItemsCount: mediaItems.length
        });
      }
    };

    preloadResources();
  }, [backgroundVideoPath, mediaItems]);

  // Extraire les conversations pour l'affichage des difficultés
  const conversationItems = mediaItems
    .filter((item): item is MediaItem & { type: 'conversation', difficulty: string } => 
      item.type === 'conversation' && !!item.difficulty)
    .map(item => ({
      ...item.data,
      difficulty: item.difficulty
    }));
  //console.log('[BrainTeaserxxxx] Conversation items:', conversationItems);

  // Calculate total duration for each media item
  const getItemDuration = (item: MediaItem): number => {
    try {
      if (item.type === 'intro') {
        const audioItem = item.data as AudioItem;
        return audioItem.duration || 0;
      } else if (item.type === 'conversation') {
        const conversation = item.data as Conversation;
        let duration = 0;
        
        if (conversation.question?.duration) {
          duration += conversation.question.duration;
        }
        if (conversation.commentary?.duration) {
          duration += conversation.commentary.duration;
        }
        if (conversation.answer?.duration) {
          duration += conversation.answer.duration;
        }
        
        // Ajouter le temps du chrono pour chaque conversation
        return duration > 0 ? duration + chronoTime : 0;
      }
    } catch (error) {
      console.error('Error calculating duration for item:', error, item);
    }
    return 0;
  };

  // Calculate accumulated durations for timing
  const itemDurations = mediaItems.map(getItemDuration);
  const totalDuration = Math.max(itemDurations.reduce((acc, dur) => acc + dur, 0), 0.001);

  //console.log('[BrainTeaserxxxx] Duration calculations:', {
  //  itemDurations,
  //  totalDuration,
  //  totalFrames: Math.floor(totalDuration * fps),
  //  chronoTimePerQuestion: chronoTime
  //});

  const getDifficultyColor = (difficulty: string) => {
    switch (difficulty) {
      case 'easy': return '#4CAF50';
      case 'medium': return '#FFC107';
      case 'hard': return '#FF5722';
      case 'extreme': return '#B71C1C';
      case 'impossible': return '#ed1782';
      case 'nightmare': return '#636363';
      default: return '#000000';
    }
  };

  const getDifficultyNumber = (index: number) => {
    return index + 1;
  };

  const CharacterSequence: React.FC<{ audioData: AudioItem, position?: 'left' | 'center' | 'bottom-center' }> = ({ audioData, position = 'left' }) => {
    //console.log('[CharacterSequence] Rendering with data:', { audioData, position });
    try {
      const frame = useCurrentFrame();
  
      const bumpAnimation = spring({
        frame,
        fps,
        config: {
          damping: 12,
          stiffness: 200,
        }
      });
  
      const characterPosition = position === 'left'
        ? interpolate(bumpAnimation, [0, 1], [-width * 0.3, 20])
        : '50%';
  
      const characterRotation = interpolate(
        frame % 120,
        [0, 60, 120],
        [-5, 5, -5]
      );
  
      const scale = 'type' in audioData && audioData.type === 'commentary'
        ? spring({
            frame,
            fps,
            config: { damping: 15, stiffness: 150 }
          })
        : 1;
  
      const getPositionStyle = () => {
        switch (position) {
          case 'center':
            return {
              bottom: '50%',
              left: '50%',
              transform: `translate(-50%, 50%) rotate(${characterRotation}deg) scale(${scale})`
            };
          case 'bottom-center':
            return {
              bottom: 20,
              left: '50%',
              transform: `translateX(-50%) rotate(${characterRotation}deg) scale(${scale})`
            };
          default:
            return {
              bottom: 20,
              left: characterPosition,
              transform: `rotate(${characterRotation}deg) scale(${scale})`
            };
        }
      };
  
      const positionStyle = getPositionStyle();
  
      return (
        <>
          <Img 
            src={audioData.characterPath}
            style={{ 
              position: 'absolute', 
              height: height * 0.3,
              width: 'auto',
              ...positionStyle
            }} 
          />
          <Audio src={audioData.audio_url} volume={1.2}/>
        </>
      );
    } catch (error) {
      console.error('[CharacterSequence] Error rendering:', error);
      return null;
    }
  };

  const SoundEffect: React.FC<{ src: string; volume?: number }> = ({ src, volume = 1 }) => {
    return <Audio src={staticFile(src)} volume={volume} />;
  };

  const DifficultyDisplay: React.FC<{ conversations: Conversation[] }> = ({ conversations }) => {
    const frame = useCurrentFrame();
    const videoConfig = useVideoConfig();
    const { fps } = videoConfig;

    const currentTime = frame / fps;
    let accumulatedTime = 0;
    
    return (
      <div style={{
        position: 'absolute',
        top: '5%',
        left: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: 58,
        fontWeight: 'bold',
        color: 'white',
        textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
        fontFamily: 'Komika',
        lineHeight: 1.2,
      }}>
        {conversations.filter(conversation => conversation.difficulty).map((conversation, index) => {
          const questionDuration = conversation.question?.duration || 0;
          const answerDuration = conversation.answer?.duration || 0;
          const totalDuration = questionDuration + chronoTime + answerDuration;

          const isCurrentQuestion = currentTime >= accumulatedTime && currentTime < accumulatedTime + totalDuration;
          
          // Afficher uniquement la difficulté
          const text = conversation.difficulty?.toUpperCase() || '';
          
          const color = getDifficultyColor(conversation.difficulty || '');

          const bumpAnimation = spring({
            frame: isCurrentQuestion ? frame : 0,
            fps,
            config: {
              damping: 12,
              stiffness: 200,
            }
          });

          const result = (
            <div key={index} style={{
              color: color,
              transform: isCurrentQuestion ? `scale(${bumpAnimation})` : 'scale(1)',
              marginBottom: '10px',
              textShadow: '1px 5px 9px black',
              WebkitTextStroke: '4px black',
              fontFamily: 'Komika',
            }}>
              {`${getDifficultyNumber(index)} - ${text}`}
            </div>
          );

          accumulatedTime += totalDuration;
          return result;
        })}
      </div>
    );
  };

  const LoadingBar: React.FC = () => {
    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();
    
    // Inverser la progression pour un décompte
    const progress = interpolate(frame, [0, chronoTime * fps], [100, 0], {
      extrapolateRight: 'clamp',
      extrapolateLeft: 'clamp',
    });

    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          height: '4rem',
          backgroundColor: 'rgb(31, 41, 55)',
          borderRadius: '1rem',
          padding: '0.5rem',
          marginBottom: '1.5rem',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            height: '100%',
            borderRadius: '0.75rem',
            transition: 'all 50ms',
            backgroundColor: '#daff00',
            width: `${progress}%`,
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              opacity: 0.2,
              animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
            }}
          />
        </div>
        <style>{`
          @keyframes pulse {
            0%, 100% {
              opacity: 0.2;
            }
            50% {
              opacity: 0.15;
            }
          }
        `}</style>
      </div>
    );
  };

  const ConversationSequence: React.FC<{ conversation: Conversation, index: number }> = ({ conversation, index }) => {
    const questionDuration = conversation.question?.duration || 0;
    const answerDuration = conversation.answer?.duration || 0;
    const totalDuration = questionDuration + chronoTime + answerDuration;

    return (
      <Sequence durationInFrames={Math.floor(totalDuration * fps)}>
        {/* Question */}
        <Sequence from={0} durationInFrames={Math.floor(questionDuration * fps)}>
          <CharacterSequence audioData={conversation.question} />
          <SoundEffect src="assets/sounds/whoosh.mp3" volume={0.5} />
        </Sequence>

        {/* Timer + Commentary */}
        <Sequence from={Math.floor(questionDuration * fps)} durationInFrames={Math.floor(chronoTime * fps)}>
          <LoadingBar />
          {conversation.commentary && (
            <CharacterSequence 
              audioData={conversation.commentary}
              position="bottom-center"
            />
          )}
          {conversation.commentary && (
            <SoundEffect src="assets/sounds/pop.mp3" volume={0.2} />
          )}
        </Sequence>

        {/* Answer avec ding au début */}
        <Sequence 
          from={Math.floor((questionDuration + chronoTime) * fps)} 
          durationInFrames={Math.floor(answerDuration * fps)}
        >
          <SoundEffect src="assets/sounds/ding.mp3" volume={0.4} />
          <CharacterSequence audioData={conversation.answer} />
          <SoundEffect src="assets/sounds/whoosh.mp3" volume={0.4} />
        </Sequence>
      </Sequence>
    );
  };

  const IntroSequence: React.FC<{ introData: AudioItem[] }> = ({ introData }) => {
    return (
      <>
        {introData.map((intro, index) => (
          <ErrorBoundary fallback={<div>Error in intro sequence</div>}>
            <Sequence
              key={`intro-${index}`}
              from={introData.slice(0, index).reduce((acc, curr) => acc + curr.duration * fps, 0)}
              durationInFrames={intro.duration * fps}
            >
              <CharacterSequence audioData={intro} position="center" />
              <div style={{
                position: 'absolute',
                top: '70%',
                left: '50%',
                transform: 'translateX(-50%)',
                fontSize: 36,
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
                textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
              }}>
              </div>
            </Sequence>
          </ErrorBoundary>
        ))}
      </>
    );
  };

  const AudioPreview: React.FC<{ src: string }> = ({ src }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const handlePlay = () => {
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        } else {
          audioRef.current.play();
          setIsPlaying(true);
        }
      }
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    return (
      <div>
        <audio ref={audioRef} src={src} onEnded={handleEnded} />
        <button onClick={handlePlay}>
          {isPlaying ? 'Stop' : 'Play'}
        </button>
      </div>
    );
  };

  const AudioPreviewManager: React.FC<{ audios: string[] }> = ({ audios }) => {
    const [currentAudio, setCurrentAudio] = useState<number | null>(null);

    const handlePlay = (index: number) => {
      if (currentAudio !== null && currentAudio !== index) {
        document.querySelectorAll('audio')[currentAudio].pause();
      }
      setCurrentAudio(index);
    };

    return (
      <div>
        {audios.map((audioSrc, index) => (
          <AudioPreview key={index} src={audioSrc} />
        ))}
      </div>
    );
  };

  return (
    <div style={{ flex: 1, backgroundColor: 'black' }}>
      {backgroundVideoPath && (
        <Video
          src={backgroundVideoPath}
          muted
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      )}
      <style>{`
        @font-face {
          font-family: 'Komika';
          src: url('https://remotionlambda-useast1-05izu22z8f.s3.us-east-1.amazonaws.com/public/fonts/Komika.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
        }
      `}</style>

      <DifficultyDisplay conversations={conversationItems} />

      {mediaItems.map((item, index) => {
        const previousDuration = itemDurations
          .slice(0, index)
          .reduce((acc, dur) => acc + dur, 0);

        const currentDuration = Math.max(getItemDuration(item) * fps, 1);
        const startFrame = Math.floor(previousDuration * fps);

        if (item.type === 'intro') {
          return (
            <Sequence
              key={`intro-${index}`}
              from={startFrame}
              durationInFrames={Math.floor(currentDuration)}
            >
              <ErrorBoundary fallback={<div>Error in intro sequence</div>}>
                <IntroSequence introData={[item.data as AudioItem]} />
              </ErrorBoundary>
            </Sequence>
          );
        } else if (item.type === 'conversation') {
          return (
            <Sequence
              key={`conversation-${index}`}
              from={startFrame}
              durationInFrames={Math.floor(currentDuration)}
            >
              <ErrorBoundary fallback={<div>Error in conversation sequence</div>}>
                <ConversationSequence 
                  conversation={item.data as Conversation} 
                  index={conversationItems.findIndex(c => c === item.data)} 
                />
              </ErrorBoundary>
            </Sequence>
          );
        }
        return null;
      })}
      <AudioPreviewManager audios={[
        'assets/sounds/ding.mp3',
        'assets/sounds/whoosh.mp3',
        'assets/sounds/pop.mp3',
      ]} />
    </div>
  );
};

// ErrorBoundary component
class ErrorBoundary extends React.Component<
  { children: React.ReactNode; fallback: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error('[BrainTeaserxxxx errors] Error in getDerivedStateFromError:', error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('[BrainTeaserxxxx errors] Component error:', {
      error,
      componentStack: errorInfo.componentStack,
      time: new Date().toISOString()
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default BrainTeaser;
;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

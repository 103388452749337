import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Player, PlayerRef, RenderLoading } from '@remotion/player';
import { RemotionSubtitles } from './SubtitlesOnly/SubtitlesOnlyTemplate';
import BrainTeaser from './BrainTeaser/BrainTeaser';
import { MotivationTemplate, calculateTotalFrames } from './Motivation/MotivationTemplate';

export const App = () => {
  const playerRef = useRef<PlayerRef>(null);

  // Initialize inputSubtitlesData in state so it can be updated dynamically
  const [inputSubtitlesData, setInputSubtitlesData] = useState({
    type: "subtitles",
    fontSize: 50,
    fontName: "TheBoldFont",
    bold: true,
    italic: true,
    wordsPerSubtitle: 3,
    subtitleStyleKey: "Neon",
    yPos: 50,
    marginBetweenWords: "10px",
    webkitTextStroke: "1px #333",
    text_shadow: null,
    textColor: "white",
    subtitles_url: "",
    subtitles_json: [],
    video_background_url: "https://oneclik-bucket.s3.eu-west-3.amazonaws.com/assets/SPLITSCREEN+VIDEOS/minecraft/Minecraft+Parkour.mp4",
    font_family: null,
    font_size: 14,
    text_format: null,
    text_color: '#FFFFFF',
    is_bold: false,
    is_italic: false,
    y_pos: 50,
    outline_color: '#000000',
    outline_size: 0,
    shadow_color: '#000000',
    shadow_size: 0,
    duration: 1,

    "backgroundVideoPath": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/assets\/SPLITSCREEN%20VIDEOS\/minecraft\/parkour10_part_16.mp4",
    "timer_sound": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/assets\/quizztimersound\/Beep Tense.mp3",
    "chrono_time": 2,
    media_items: [
      {
        "type": "intro",
        "data": {
          "text": "Hey",
          "characterPath": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/assets\/characters\/Bart%20Simpsons\/Bart%20%26%20Homer%20Simpsons%20Entry%201.png",
          "audio_url": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/parrot-ai-voices\/f20a3ad0-e2a8-4083-a5b8-96a6c292be32.mp3",
          "duration": 0.552
        },
        "difficulty": "easy"
      },
      {
        "type": "conversation",
        "data": {
          "question": {
            "type": "question",
            "text": "Hey",
            "characterPath": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/assets\/characters\/Dicaprio\/1.png",
            "audio_url": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/parrot-ai-voices\/ae5fc603-4075-411e-ba02-372a189cdbe0.mp3",
            "duration": 0.552
          },
          "commentary": {
            "type": "commentary",
            "text": "Hey",
            "characterPath": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/assets\/characters\/Dicaprio\/1.png",
            "audio_url": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/parrot-ai-voices\/cab01bd5-9254-4b47-887e-2a1aed499e09.mp3",
            "duration": 0.36
          },
          "answer": {
            "type": "answer",
            "text": "Hey",
            "showed_text": "Hey",
            "characterPath": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/assets\/characters\/Dicaprio\/1.png",
            "audio_url": "https:\/\/oneclik-bucket.s3.eu-west-3.amazonaws.com\/parrot-ai-voices\/5e1728c3-4d77-42a4-bd6a-db0a6284e950.mp3",
            "duration": 0.456
          }
        },
        "difficulty": "easy"
      },
    ]
  });

  const [playerKey, setPlayerKey] = useState(0);

  const renderLoading: RenderLoading = useCallback(({height, width}) => {
    return (
      <div style={{
        height,
        width,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f5f5f5',
        color: '#333'
      }}>
        <div>Chargement...</div>
        <div>({width}x{height})</div>
      </div>
    );
  }, []);

  // Handle messages from the parent window
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!playerRef.current) return;

      // Check the message type and perform corresponding action
      switch (event.data.type) {
        case 'play':
          playerRef.current.play();
          break;
        case 'pause':
          playerRef.current.pause();
          break;
        case 'subtitles':
          // TODO with event.data.payload
          break;
        case 'update-param':
          console.log(event.data.payload)
          inputSubtitlesData[event.data.payload.key] = event.data.payload.value;
          console.log('Updated inputSubtitlesData:', inputSubtitlesData);
          playerRef.current.toggle();

          setTimeout(() => {
            playerRef.current.toggle();
          }, 10);

          if (['duration', 'type'].includes(event.data.payload.key)) {
            setPlayerKey((key) => key + 1);
          }

          break;
        case 'update-params':
          console.log(event.data.payload)
          for (const key in event.data.payload) {
            inputSubtitlesData[key] = event.data.payload[key];
          }
          console.log('Updated inputSubtitlesData:', inputSubtitlesData);
          playerRef.current.toggle();

          setTimeout(() => {
            playerRef.current.toggle();
          }, 10);
          break;
      }
    };

    // Listen for messages from the parent window
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!playerRef.current) return;

    const playerElement = playerRef.current

    const handleFrameUpdate = (e: CustomEvent) => {
      const currentFrame = e.detail.frame;
      const currentTime = currentFrame / 30; 

      window.parent.postMessage(
        {
          type: 'timeupdate',
          payload: {
            currentTime,
            frame: currentFrame,
          },
        },
        '*'
      );
    };

    // Add event listener for frame updates
    console.log('playerElement', playerElement)
    playerElement.addEventListener('frameupdate', handleFrameUpdate as EventListener);

    return () => {
      // Cleanup event listener on unmount
      playerElement.removeEventListener('frameupdate', handleFrameUpdate as EventListener);
    };
  }, []);

  return (
    <Player      
      key={playerKey}
      ref={playerRef}
      component={inputSubtitlesData.type === 'subtitles' ? RemotionSubtitles : BrainTeaser}
      durationInFrames={inputSubtitlesData.duration * 30}
      compositionWidth={270}
      compositionHeight={480}
      fps={30}
      style={{ width: '270px', height: '480px' }}
      autoPlay={true}
      loop={true}
      controls={true}
      initiallyMuted={true}
      inputProps={inputSubtitlesData}
      showServerDisconnectedOverlay={false}
      lowRes={true}
      spareRenders={true}      
      initialFrame={1}
      renderLoading={renderLoading}
      quality={0.5}
      enablePrefetch={true}
      enableCache={true}
      maxTimelineTracks={1}
      numberOfSharedAudioTags={1}
      playbackSpeed={1}
      renderMode="preview"
    />
  );
};
;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

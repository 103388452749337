import React, { useState, useEffect } from 'react';
import {
  AbsoluteFill,
  useCurrentFrame,
  useVideoConfig,
  spring,
  interpolate,
  Video,
  continueRender,
  delayRender,
} from 'remotion';

interface SubtitlesProps {
  data: {
    y_pos: number;
    is_bold: boolean;
    font_size: number;
    is_italic: boolean;
    text_color: string;
    font_family: string;
    shadow_size: number;
    text_format: string;
    outline_size: number;
    shadow_color: string;
    outline_color: string;
    video_background_url: string;
    subtitles_url?: string;
    subtitles_json?: any;
    media_items: Array<{
      type: string;
      url: string;
      duration_in_seconds: number;
    }>;
  };
}

const Word = ({ word, startTime, endTime, style, text_format }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const startFrame = Math.floor(startTime * fps);
  const endFrame = Math.floor(endTime * fps);

  if (frame < startFrame || frame > endFrame) return null;

  const progress = spring({
    frame: frame - startFrame,
    fps,
    from: 0,
    to: 1,
    durationInFrames: 15,
  });

  const scale = interpolate(progress, [0, 0.5, 1], [0.5, 1.2, 1]);
  const y = interpolate(progress, [0, 0.5, 1], [20, -5, 0]);

  let formattedWord = word;
  switch (text_format) {
    case 'uppercase': formattedWord = word.toUpperCase(); break;
    case 'lowercase': formattedWord = word.toLowerCase(); break;
    case 'capitalize': formattedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); break;
  }

  return (
    <span style={{
      ...style,
      display: 'inline-block',
      transform: `scale(${scale}) translateY(${y}px)`,
      margin: '0 10px',
    }}>
      {formattedWord}
    </span>
  );
};

export const calculateMetadata = (props) => {
  const duration = props?.props?.media_items?.[0]?.duration_in_seconds;
  
  return {
    durationInFrames: Math.ceil(duration * 60),
    fps: 60,
    width: 1080,
    height: 1920,
  };
};

export const RemotionSubtitles: React.FC<SubtitlesProps> = ( data ) => {
  const [subtitles, setSubtitles] = useState(null);
  const [handle] = useState(() => delayRender());

  const style = {
    fontSize: `${data.font_size}px`,
    fontWeight: data.is_bold ? 'bold' : 'normal',
    fontStyle: data.is_italic ? 'italic' : 'normal',
    WebkitTextStroke: data.outline_size > 0 ? `${data.outline_size}px ${data.outline_color}` : 'none',
    textShadow: data.shadow_size > 0 ? `${data.shadow_size}px ${data.shadow_size}px ${data.shadow_color}` : 'none',
    color: data.text_color,
    position: 'absolute' as const,
    width: '100%',
    textAlign: 'center' as const,
    bottom: `${data.y_pos}%`,
    fontFamily: data.font_family || 'Arial',
    lineHeight: 1.5,
    padding: '0 20px',
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      #server-disconnected-overlay, .css-reset {
        display: none !important;
      }
    `;
    document.head.appendChild(style);
  
    // Cleanup to remove the style when component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    if (data.subtitles_json) {
      setSubtitles(data.subtitles_json);
      continueRender(handle);
    } else if (data.subtitles_url) {
      fetch(data.subtitles_url)
        .then(response => response.json())
        .then(loadedSubtitles => {
          const formattedSubtitles = loadedSubtitles.results.items
          .filter(item => item.type == 'pronunciation')
          .map(item => {
              return {
                  start_time: item.start_time,
                  end_time: item.end_time,
                  text: item.alternatives.length ? item.alternatives[0].content : ''
              }
          })
          setSubtitles(formattedSubtitles);
          continueRender(handle);
        })
        .catch(error => {
          console.error("Error loading subtitles:", error);
          continueRender(handle);
        });
    } else {
      console.error("No subtitles source provided.");
      continueRender(handle);
    }
  }, [data.subtitles_url, data.subtitles_json, handle]);

  if (!subtitles) return null;

  return (
    <AbsoluteFill>
      <Video
        src={data.video_background_url}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <AbsoluteFill style={{ zIndex: 1 }}>
        {subtitles
          .map((item, index) => (
            <Word
              key={index}
              word={item.text}
              startTime={parseFloat(item.start_time || '0')}
              endTime={parseFloat(item.end_time || '0')}
              style={style}
              text_format={data.text_format}
            />
          ))}
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
